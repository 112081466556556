.ant-layout-header {
  background: none;
  margin: 16px 32px 32px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  z-index: 3;
  padding: 0;

  >.ant-row {
    height: 100%;
    align-items: center;

    >.ant-col {
      background: @b-0;
      border-radius: 7px;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 24px;
    }
  }

  &.@{class-prefix}-header-horizontal {
    margin: 16px 32px 32px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    z-index: 3;
    padding: 0;

    .ant-menu.ant-menu-root {
      position: relative;
      justify-content: center;
      align-items: center;
      border: none;
      margin: 0;
      vertical-align: middle;
      top: 0;

      .ant-menu-submenu-selected {
        .menu-item {
          background: @primary-1  !important;
          color: @b-0  !important;

          svg path {
            fill: @b-0  !important;
          }
        }
      }

      >li {
        line-height: normal;

        &:after {
          visibility: hidden;
        }
      }

      @media @lg {
        display: none;
      }
    }

    &.@{class-prefix}-header-full,
    &.@{class-prefix}-header-bg {
      padding: 16px 32px;
      margin: 0 0 32px;
      background-color: @b-0;
      border-radius: 0px;

      >.ant-row {
        >.ant-col {
          padding: 0;
        }
      }
    }
  }

  .@{class-prefix}-header-search {
    opacity: 0;
    visibility: hidden;
    transform: translate(-30px, 0);
    transition: 0.4s;

    &.@{class-prefix}-header-search-active {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }

    &-result {
      position: absolute;
      top: 100%;
      left: 0;
      right: 10px;
      background: @b-0;
      border-radius: 0 0 16px 16px;
      overflow: hidden;
      line-height: normal;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          height: auto;
          transition: 0.4s;
          font-size: 12px;
          line-height: normal;

          a {
            display: block;
            padding: 10px 40px;
            color: @b-100;
          }

          &:hover {
            background-color: @primary-4;

            a {
              color: @primary-1
            }
          }
        }
      }
    }

    @media @md {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -70px;
      top: auto;

      .@{class-prefix}-header-search-result {
        top: calc(100% - 10px);
        border-radius: 16px;
        right: 0;
      }
    }
  }

  .@{class-prefix}-update-icon {
    min-width: 18px;
    border-radius: 9px;
  }

  .@{class-prefix}-mobile-sidebar-button {
    display: none;
  }

  .@{class-prefix}-header-left-text {
    &-item {
      line-height: 32px;

      .remix-icon {
        vertical-align: text-bottom;
      }
    }
  }

  @media screen and (max-width: 1380px) {
    .@{class-prefix}-header-left-text {
      &-item {
        line-height: normal;
      }
    }
  }

  @media @lg {
    .@{class-prefix}-mobile-sidebar-button {
      display: block;
    }

    .@{class-prefix}-header-left-text {
      display: none !important;
    }

    &.@{class-prefix}-header-horizontal {
      .@{class-prefix}-header-logo {
        display: none;
      }
    }
  }

  @media @sm {
    margin: 16px 16px 32px;

    >.ant-row {
      >.ant-col {
        padding: 0 16px 0 8px;
      }
    }

    &.@{class-prefix}-header-horizontal {
      margin: 16px 16px 32px;

      &.@{class-prefix}-header-full,
      &.@{class-prefix}-header-bg {
        padding: 16px 16px;
      }
    }
  }
}

// Basket
.@{class-prefix}-basket-dropdown-button:hover {
  .@{class-prefix}-basket-dropdown {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.@{class-prefix}-basket-dropdown {
  position: absolute;
  min-width: 330px;
  padding: 35px 16px 8px;
  top: 30px;
  right: -10px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s;

  &.remove-cart {
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
  }

  &-list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 358px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 29px;
    left: 0;
    right: 0;
    bottom: 0;
    background: @b-0;
    border: 1px solid @b-40;
    border-radius: 8px;
  }

  >div {
    position: relative;
  }

  @media @sm {
    right: 0;
    left: 10%;
    transform: translate(-50%, 0px);
    min-width: 300px;
  }
}

// RTL
html[dir="rtl"] {
  .ant-layout-header {
    @media @sm {
      >.ant-row {
        >.ant-col {
          padding: 0 8px 0 16px;
        }
      }
    }
  }

  .@{class-prefix}-basket-dropdown {
    left: -10px;
    right: auto;

    @media @sm {
      transform: translate(-35%, 0px);
    }
  }
}