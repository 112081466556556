.@{class-prefix}-landing {
  &-container {
    max-width: 1184px;
    margin: 0 auto;
    //padding: 0 32px;

    @media @sm {
      padding: 0 16px;
    }
  }

  // Header
  header {
    .@{class-prefix}-landing-header {
      &-menu {
        .ant-menu {
          border-bottom: none;

          .ant-menu-item:after {
            visibility: hidden;
          }
        }
      }

      &-mobile-button {
        display: none;
      }
    }

    @media @sm {
      .@{class-prefix}-landing-header {
        &-menu {
          display: none !important;
        }

        &-buttons {
          display: none;
        }

        &-mobile-button {
          display: block;
        }
      }
    }
  }

  // Hero
  &-hero {
    position: relative;
    overflow: hidden;

    &-title {
      max-width: 790px;
      margin: 0 auto;
    }

    &-img {
      position: relative;
      //max-width: 1100px;      
      //margin: 0 auto;

      &-container {
        width: 100%;
        height: 250px;
        margin-top: 56px;
        //height: 100%;
        transform: translate(0px, 10px);
        background-color: black;
      }

      &-item {
        background-size: contain;
        background-position: center bottom;
        background-repeat: no-repeat;
        height: 100%;
        position: relative;
        filter: drop-shadow(2px 4px 46px rgba(0, 0, 0, 0.1));
      }

      &-left {
        position: absolute;
        bottom: 20px;
        left: 6%;

        .@{class-prefix}-landing-hero-img-emoji {
          &:nth-child(1) {
            font-size: 40px;
            transform: rotate(28deg);
            margin-bottom: 80px;
            margin-left: 40px;
          }

          &:nth-child(2) {
            margin-left: 30px;
            margin-bottom: 0px;
          }

          &:nth-child(3) {
            font-size: 40px;
            transform: rotate(28deg);
            margin-bottom: 20px;
            margin-left: -30px;
          }
        }
      }

      &-right {
        position: absolute;
        bottom: 30px;
        right: 6%;
        text-align: center;

        .@{class-prefix}-landing-hero-img-emoji {
          &:nth-child(1) {
            font-size: 20px;
            margin-bottom: 60px;
            margin-left: 70px;
          }

          &:nth-child(2) {
            font-size: 40px;
            margin-bottom: 20px;
            margin-left: -40px;
          }

          &:nth-child(3) {
            margin-bottom: 30px;
            margin-left: -220px;
          }
        }
      }
    }

    &-rectangle {
      border-radius: 50px;
      width: 150px;
      position: absolute;
      bottom: -5px;
      left: 0px;
      z-index: 20;
      height: 150px;
    }

    &-circle {
      width: 282px;
      height: 282px;
      position: absolute;
      top: -60px;
      right: -40px;
      height: 256px;
    }

    @media screen and (max-width: 1450px) {
      &-img {
        &-container {
          width: 100%;
          height: 450px;
          margin-top: 56px;
          //height: 100%;
          transform: translate(0px, 10px);
          background-color: black;
        }

        &-item {
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          height: 100%;
          position: relative;
          filter: drop-shadow(2px 4px 46px rgba(0, 0, 0, 0.1));
        }
        
        &-left {
          left: 30px;
        }

        &-right {
          right: 30px;
        }
      }
    }

    @media @lg {
      &-img {
        max-width: 80vw;
        height: 360px;
      }
    }

    @media @md {
      &-title {
        max-width: 580px;
      }

      &-img {
        max-width: 100%;
        height: 42vw;

        &-left,
        &-right {
          transform: scale(0.7);
          transform-origin: bottom;
        }

        &-left {
          left: 0;
        }

        &-right {
          right: 0;
        }
      }
    }

    @media @sm {
      &-rectangle {
        width: 50px;
        height: 50px;
        bottom: -8px;
        left: 0px;
      }

      &-circle {
        width: 122px;
        height: 122px;
        right: -6px;
        top: -40px;
      }

      &-title {
        max-width: 100%;

        p {
          font-size: 18px;
        }
      }

      &-img {
        max-width: 100%;
        height: 42vw;

        &-left,
        &-right {
          transform: scale(0.5);
          bottom: 10px;
        }

        &-left {
          .@{class-prefix}-landing-hero-img-emoji {
            &:nth-child(1) {
              margin-bottom: 0;
            }

            &:nth-child(3) {
              margin-left: 0px;
            }
          }
        }

        &-right {
          .@{class-prefix}-landing-hero-img-emoji {
            &:nth-child(1) {
              margin-bottom: 20px;
              margin-left: 40px;
            }

            &:nth-child(3) {
              margin-left: 30px;
            }
          }
        }
      }
    }
  }

  &-companies {
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-header {
      padding-top: 80px;
    }

    &-col {
      height: 100px;
      width: 250px;
      margin-bottom: 20px;
    }

    &-image {
      height: 90px;
      margin-left: 30px;
    }
    
    @media screen and (max-width: 1180px) {

      &-header {
        padding-top: 30px;
        padding-bottom: 20px;
      }

      &-image {
        height: 30px;
        margin-left: 10px;
      }

      &-col {
        height: 22px;
        width: 142px;
        // margin-bottom: 20px;
      }
    }
  }

  // Features
  &-features {
    .slick-slider {
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 180px;
        background: linear-gradient(to left, #ffffff, #ffffff00);
        z-index: 1;

        @media @sm {
          width: 50px;
        }
      }

      &:before {
        left: 0;
        right: auto;
        transform: rotate(180deg);
      }

      &:after {
        right: 0;
        left: auto;
      }
    }

    .slick-list {
      padding-left: 230px;

      @media screen and (max-width: 1370px) {
        padding-left: 150px;
      }

      @media @lg {
        padding-left: 80px;
      }

      @media @sm {
        padding-left: 24px;
      }
    }
  }

  // Content 1
  &-content-1 {
    //padding-top: 160px;

    .@{class-prefix}-landing-container {
      //max-width: 75vw;
    }

    &-circle {
      width: 200px;
      height: 200px;
      border-radius: 100%;
      margin-left: auto;
    }

    &-img {
      //position: absolute;
      //right: -152px;
      //top: 58%;
      //transform: translate(0px, -50%);

      img {
        //max-width: 880px;
      }
    }

    @media screen and (max-width: 1450px) {
      .@{class-prefix}-landing-container {
        max-width: 83vw;
      }
    }

    @media @lg {
      &-img {
        img {
          max-width: 100%;
        }
      }
    }

    @media @md {
      
      .@{class-prefix}-landing-container {
        max-width: 100%;

        > .ant-row {
          justify-content: center;
        }
      }

      &-circle {
        width: 500px;
        height: 500px;
      }

      &-img {
        //right: -40px;
        text-align: right;

        img {
          //max-width: 90%;
        }
      }
    }

    @media @sm {
     
      &-circle {
        width: 30px;
        height: 30px;
      }

      h3 {
        font-size: 18px;
        line-height: normal;
      }
    }
  }

  // Content 2
  &-content-2 {
    padding-top: 144px;
    //padding-top: 160px;

    .@{class-prefix}-landing-container {
      //max-width: 75vw;
    }

    &-circle {
      width: 200px;
      height: 200px;
      border-radius: 100%;
      margin-left: auto;
    }

    &-img {
      //position: absolute;
      //right: -152px;
      //top: 58%;
      //transform: translate(0px, -50%);

      img {
        //max-width: 880px;
      }
    }

    @media screen and (max-width: 1450px) {
      .@{class-prefix}-landing-container {
        max-width: 83vw;
      }
    }

    @media @lg {
      &-img {
        img {
          max-width: 100%;
        }
      }
    }

    @media @md {
      padding-top: 0px;

      .@{class-prefix}-landing-container {
        max-width: 100%;

        > .ant-row {
          justify-content: center;
        }
      }

      &-circle {
        width: 500px;
        height: 500px;
      }

      &-img {
        // right: -40px;
        // text-align: right;

        img {
          // max-width: 90%;
        }
      }
    }

    @media @sm {
      &-circle {
        width: 30px;
        height: 30px;
      }

      h3 {
        font-size: 18px;
        line-height: normal;
      }
    }
  }

  // People
  &-people {
    &-circle {
      width: 542px;
      height: 542px;
      border-radius: 100%;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -60%);

      @media @sm {
        width: 342px;
        height: 342px;
      }
    }

    &-item {
      min-height: 200px;

      &-twitter {
        position: absolute;
        top: 16px;
        right: 20px;
      }

      &-img {
        margin-left: -10px;
        margin-right: 10px;
      }

      @media @md {
        &-title {
          flex: 0 0 100%;
        }
      }
    }
  }

  // Pricing
  &-pricing {
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 450px;

      @media @sm {
        min-height: initial;
      }
    }
  }

  // Footer
  &-footer {
    &-circle {
      width: 255px;
      height: 255px;
      border-radius: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-50%, 50%);
    }

    @media @sm {
      &-circle {
        width: 200px;
        height: 200px;
      }

      &-copyright {
        text-align: center;

        > .ant-col {
          flex: 100%;

          .ant-row {
            justify-content: center;
          }
        }
      }
    }
  }
}

// RTL
html[dir="rtl"] {
  .@{class-prefix}-landing {
    // Hero
    &-hero {
      &-img {
        &-left {
          left: auto;
          right: 6%;

          .@{class-prefix}-landing-hero-img-emoji {
            &:nth-child(1) {
              margin-right: 40px;
              margin-left: 0px;
            }

            &:nth-child(2) {
              margin-right: 30px;
              margin-left: 0px;
            }

            &:nth-child(3) {
              margin-right: -30px;
              margin-left: 0px;
            }
          }
        }

        &-right {
          left: 6%;
          right: auto;

          .@{class-prefix}-landing-hero-img-emoji {
            &:nth-child(1) {
              margin-right: 70px;
              margin-left: 0px;
            }

            &:nth-child(2) {
              margin-right: -40px;
              margin-left: 0px;
            }

            &:nth-child(3) {
              margin-right: -220px;
              margin-left: 0px;
            }
          }
        }
      }

      @media screen and (max-width: 1450px) {
        &-img {
          &-left {
            right: 30px;
          }

          &-right {
            left: 30px;
          }
        }
      }

      @media @md {
        &-img {
          &-left {
            right: 0;
          }

          &-right {
            left: 0;
          }
        }
      }

      @media @sm {
        &-img {
          &-right {
            .@{class-prefix}-landing-hero-img-emoji {
              &:nth-child(1) {
                margin-right: 40px;
              }

              &:nth-child(3) {
                margin-right: 30px;
              }
            }
          }
        }
      }
    }

    // Content 1
    &-content-1 {
      &-circle {
        margin-right: auto;
        margin-left: 0;
      }

      &-img {
        left: -152px;
        right: auto;
        transform: translate(0px, -50%) scale(-1, 1);
      }

      @media @md {
        &-img {
          left: -40px;
          right: auto;
          text-align: left;
        }
      }
    }

    // People
    &-people {
      &-circle {
        left: 0;
        right: auto;
        transform: translate(-50%, -60%);
      }

      &-item {
        &-twitter {
          left: 20px;
          right: auto;
        }

        &-img {
          margin-right: -10px;
          margin-left: 10px;
        }
      }
    }

    // Footer
    &-footer {
      &-circle {
        right: 0;
        transform: translate(50%, 50%);
      }
    }
  }
}
